import React from 'react'
import { images, data } from '../../constants'
import './SpecialMenu.css'
import { SubHeading, MenuItem } from '../../components'

const SpecialMenu = () => (
	<div className="app__specialMenu flex__center section__padding" id="menu">
		<div className="app__specialMenu-title">
			<SubHeading title="Menu that fits your palatte" />
			<h1 className="headtext__cormorant">Todays's Special</h1>
		</div>

		<div className="app__specialMenu-menu">
			<div className="app__specialMenu-menu_wine flex__center">
				<p className="app__specialMenu-menu_heading">Wine & Beer</p>
				<div className="app__specialMenu_menu_items">
					{data.wines.map((item, i) => (
						<MenuItem key={i} title={item.title} tags={item.tags} price={item.price} />
					))}
				</div>
			</div>

			<div className="app__specialMenu-menu_img">
				<img src={images.menu} alt="menu img" />
			</div>

			<div className="app__specialMenu-menu_cocktails flex__center">
				<p className="app__specialMenu-menu_heading">Cocktails</p>
				<div className="app__specialMenu_menu_items">
					{data.cocktails.map((item, i) => (
						<MenuItem key={i} title={item.title} tags={item.tags} price={item.price} />
					))}
				</div>
			</div>
		</div>

		<div style={{ marginTop: '15px' }}>
			<button type="button" className="custom__button">
				View More
			</button>
		</div>
	</div>
)

export default SpecialMenu
